<template>
  <!-- 头部 -->
  <div class="head">
    <div class="head-item w d-flex align-center">

      <ul class="tab d-flex">
        <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('/')"
          :class="{ tab_active: url == '/' }"
        >
          <div class="item-name">首页</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li>
        <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('course')"
          :class="{ tab_active: url == 'course' }"
        >
          <div class="item-name">课程中心</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li>
        <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('question')"
          :class="{ tab_active: url == 'question' }"
        >
          <div class="item-name">海量题库</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li>
        <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('live')"
          :class="{ tab_active: url == 'live' }"
        >
          <div class="item-name">直播中心</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li>
        <!-- <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('teachers')"
          :class="{ tab_active: url == 'teachers' }"
        >
          <div class="item-name">师资阵容</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li> -->
        <li
          class="item d-flex flex-column align-center cursor"
          @click="goTo('information')"
          :class="{ tab_active: url == 'information' }"
        >
          <div class="item-name">学习资料</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li>
        <!-- <li class="item d-flex flex-column align-center cursor">
          <div class="item-name">下载APP</div>
          <div class="item-img">
            <img src="../assets/img/tab_active.png" alt="" />
          </div>
        </li> -->
      </ul>
      <ul class="d-flex head-right align-center">
        <li class="phone d-flex align-center justify-end">
        </li>
        <li v-show="!show" class="sign d-flex align-center justify-center">
          <div class="sign-item cursor" @click="goLogin">登录</div>
          <div class="line"></div>
          <div class="sign-item cursor" @click="goRegister">注册</div>
        </li>
        <li
          v-show="show"
          class="user d-flex align-center cursor"
          @click="goUser"
        >
          <div class="user-img">
            <img v-if="imgUrl" :src="imgUrl" alt="" />
            <img v-else src="../assets/img/ava.png" alt="" />
          </div>
          <div class="user-name">{{ name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      url: "",
      name: "",
      show: false,
      imgUrl: ""
    };
  },
  props: {
    routeUrl: {
      type: String,
      default: ""
    }
  },
  watch: {
    $route: {
      handler(route) {
        if (this.routeUrl) {
          this.url = this.routeUrl;
        } else {
          this.url = route.name;
        }
      },
      immediate: true
    }
  },
  mounted() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.name = user.rname;
      this.imgUrl = user.icon;
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    goTo(id) {
      if (this.url != id) {
        this.url = id;
        this.$router.push(id);
      }
    },
    goUser() {
      this.$router.push("start");
    },
    goLogin() {
      this.$router.push("login");
    },
    goRegister() {
      this.$router.push({
        name: `login`,
        params: {
          loginIndex: "1"
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.tab_active {
  .item-name {
    color: #5f2eff !important;
  }
  .item-img {
    img {
      display: block !important;
    }
  }
}
// 头部
.head {
  min-width: 1200px;
  background-color: #fff;
  .head-item {
    height: 70px;
    .head-img {
      width: 128px;
      height: 34px;
      margin-right: 50px;
    }
    .tab {
      .item {
        margin-right: 28px;
        margin-top: 12px;
        .item-name {
          font-size: 16px;
          color: #555555;
        }
        .item-img {
          width: 23px;
          height: 10px;
          margin-top: 6px;
          img {
            display: none;
          }
        }
      }
    }
    .head-right {
      margin-left: auto;
      .user {
        margin-left: 20px;
        .user-img {
          width: 24px;
          height: 24px;
          border-radius: 12px;
          overflow: hidden;
        }
        .user-name {
          font-size: 12px;
          color: #5f2eff;
          margin-left: 4px;
        }
      }
      .sign {
        border-radius: 15px;
        font-size: 16px;
        color: #5f2eff;
        margin-left: 20px;
        width: 100px;
        height: 28px;
        border: 1px solid #cccccc;
        border-radius: 14px;
        .sign-item {
          font-size: 14px;
          color: #5528e9;
        }
        .line {
          width: 1px;
          height: 14px;
          background: #5f2eff;
          margin: 0 6px;
        }
      }
      .phone {
        font-size: 14px;
        color: #444444;
        img {
          width: 13px;
          height: 14px;
        }
      }
    }
  }
}
</style>
