<template>
  <!-- 头部 -->
  <div class="head">
    <div class="head-item w d-flex align-center">

      <ul class="d-flex head-right align-center">
        <!-- <li class="sign d-flex align-center justify-center">今日签到</li> -->
        <!-- <li class="head-line"></li> -->
        <li class="phone d-flex align-center justify-end">
        </li>
        <li class="head-line"></li>
        <li
          class="sign d-flex align-center justify-center cursor"
          @click="tuichu"
        >
          安全退出
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {};
  },
  methods: {
    goTo(id) {
      this.$router.push(id);
    },
    tuichu() {
      window.localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="less" scoped>
// 头部
.head {
  min-width: 1200px;
  background-color: #fff;
  .head-item {
    height: 70px;
    .head-img {
      width: 128px;
      height: 34px;
    }
    .head-right {
      margin-left: auto;
      .sign {
        width: 90px;
        height: 30px;
        border: 1px solid #5f2eff;
        border-radius: 15px;
        font-size: 16px;
        color: #5f2eff;
      }
      .head-line {
        width: 1px;
        height: 40px;
        background: #dddddd;
        margin: 0 18px;
      }
      .phone {
        font-size: 18px;
        color: #444444;
        img {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
}
</style>
