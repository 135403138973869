<template>
  <!-- 底部 -->
  <div class="foot">
    <div class="foot-top">
      <div class="w d-flex justify-between">
        <div class="foot-left">
          <div class="foot-title">联系我们</div>
          <div class="foot-c">地址 ：天津市河西区围堤道146号华盛广场B座27层e单元</div>
          <!-- <ul class="foot-list d-flex align-center">
            <li>定向保定</li>
            <li>农业硕士</li>
            <li>免联考</li>
          </ul> -->
        </div>
        <div class="foot-r d-flex">
          <div class="right-item d-flex align-center flex-column">
            <div class="item-img">
            </div>
          </div>
          <div class="right-item d-flex align-center flex-column">
            <div class="item-img">
              <img src="../assets/img/xcx.jpg" alt="" />
            </div>
            微信小程序
          </div>
        </div>
      </div>
    </div>
    <div class="w foot-b d-flex justify-between">
      <div class="foot-b-item">Copyright © 优仕教育 <a style="color: #ffffff;" href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">津ICP备2023004451号</a></div>
      <div class="foot-b-item"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers",
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
//底部
.foot {
  max-width: 1920;
  min-width: 1200px;
  height: 250px;
  background: #30343e;
  margin-top: 64px;
  .foot-top {
    height: 204px;
    border-bottom: 1px solid rgba(245, 248, 252, 0.3);
    .foot-title {
      font-size: 24px;
      color: #ffffff;
      padding: 25px 0px 20px 0px;
      box-sizing: border-box;
    }
    .foot-c {
      font-size: 16px;
      color: #ffffff;
      line-height: 36px;
    }
    .foot-list {
      margin-top: 30px;
      li {
        font-size: 14px;
        color: #ffffff;
        padding: 0 40px;
        box-sizing: border-box;
        position: relative;
        &:first-child {
          padding-left: 0;
        }
        &::after {
          content: "";
          height: 11px;
          width: 1px;
          background: rgba(255, 255, 255, 0.3);
          position: absolute;
          top: 5px;
          right: 0;
        }
        &:last-child::after {
          display: none;
        }
      }
    }
    .foot-r {
      .right-item {
        font-size: 16px;
        color: #ffffff;
        margin-top: 39px;
        margin-left: 30px;
        .item-img {
          width: 120px;
          height: 120px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .foot-b {
    padding-top: 13px;
    box-sizing: border-box;
    .foot-b-item {
      font-size: 11px;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}
</style>
