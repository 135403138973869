import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '@/router'
import { getToken } from './auth'
import { Message, MessageBox } from 'element-ui'
import config from "./config";

const BASE_URL = '/invoice'
const TIMEOUT_MILLISECONDS = 60000 // 超时链接

const instance = axios.create({
  withCredentials: false,
  baseURL: 'https://mvpaaa.com:9000', // 基本url
  timeout: TIMEOUT_MILLISECONDS,
  headers: {
    'Content-Type': 'application/json'
  }
})
// &t=${timestamp}
instance.interceptors.request.use((config) => {
  // 每个接口新增时间戳
  let timestamp = new Date().getTime()
  if (config.url && config.url.includes('?')) {
    config.url = `${config.url}`
  } else {
    config.url = `${config.url}`
  }
  //PUT POST DELETE方式提交的数据格式化
  if ((config.method === 'post' || config.method === 'put' || config.method === 'delete') && config.headers['Content-Type'] !== 'application/json') {
    config.data = qs.stringify(config.data)
  }
  // 在发送请求之前 判断是否存在token，如果存在的话，则每个http header都加上token
  if (getToken()) {
    // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
    config.headers['authorization'] = getToken()
  }
  return config
},
  (error) => Promise.reject(error)
)
instance.interceptors.response.use((response) => {
  const responseData = response.data;
  // 下载类型特殊处理文件名
  const type = response.request.responseType || ''
  if (type.includes('blob')) {
    let disposition = response.headers['content-disposition']
    let filename = '默认文件名'
    if (disposition && disposition.indexOf('filename=') !== -1) {
      filename = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
    }
    response.data.filename = filename
  }
  if (responseData.code == 200) {
    //返回自定义信息
    return response.data
  } else if (responseData.code == 4010002||responseData.code == 4010001 ||responseData.code ==1000001) {
    console.log(router.currentRoute.fullPath);
    router.replace("/login");
    window.localStorage.clear();
    Message.error({
      message: '登录失效，请重新登录！'
    })
    // MessageBox.confirm(responseData.message, "重新登录", {
    //   showCancelButton: false,
    //   showClose: false,
    //   confirmButtonText: "重新登录",
    //   type: "warning"
    // }).then(res => { });
    // router.replace({
    //   name: '/login',
    //   query: {
    //     redirect: router.currentRoute.fullPath
    //   }
    // })
    // window.localStorage.clear();
    return false;
  } else {
    return response.data
  }

}, (error) => {
  console.log(error)
  switch (error.response && error.response.status) {
    case 400:
      error.message = '请求错误(400)'
      Message.error({
        message: error.response.data.msg || error.message
      })
      break
    case 4010002:
      error.message = '登录信息已过期，请重新登录'// (401)
      MessageBox.confirm(error.response.data.msg || error.message, {
        title: '提示',
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        router.replace("/login");
        window.localStorage.clear();
      })
      break
    case 403:
      error.message = '拒绝访问(403)'
      break
    case 404:
      error.message = '请求出错(404)'
      break
    case 408:
      error.message = '请求超时(408)'
      Message.error({
        message: error.response.data.msg || error.message
      })
      break
    case 500:
      error.message = '服务器错误(500)'
      Message.error({
        message: error.response.data.msg || error.message
      })
      break
    case 501:
      error.message = '服务未实现(501)'
      break
    case 502:
      error.message = '网络错误(502)'
      break
    case 503:
      error.message = '服务不可用(503)'
      break
    case 504:
      error.message = '网络超时(504)'
      break
    case 505:
      error.message = 'HTTP版本不受支持(505)'
      break
    case 5000001:
      error.message = '网络异常'
      break
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    Message.error({
      message: '网络异常'
    })
  }
  return Promise.reject(error)
})
export default instance
