import Vue from "vue";
import axios from 'axios'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import headers from "./components/headers.vue";
import footers from "./components/footers.vue";
import heads from "./components/heads.vue";
import foots from "./components/foots.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// import { setCookie, getCookie, delCookie } from '../src/utils/cookie';
import { ajaxRequest } from '@/api'
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

// import api from "./api";

// Vue.config.productionTip = false;
Vue.prototype.$ajaxRequest = ajaxRequest
axios.defaults.withCredentials = true
// Vue.prototype.$api = api;
// Vue.prototype.$cookieStore = { setCookie, getCookie, delCookie };

Vue.use(ElementUI);
Vue.component("headers", headers);
Vue.component("footers", footers);
Vue.component("heads", heads);
Vue.component("foots", foots);
Vue.use(VueAwesomeSwiper);

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
