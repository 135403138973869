<template>
  <!-- 底部 -->
  <div class="foot">
    <div class="w">
      <ul class="foot-list d-flex align-center justify-center">
        <li>教学资格证</li>
        <li>自学考试</li>
        <li>大健康</li>
        <li>普通话</li>
        <li>研究生课程</li>
      </ul>
      <div class="icp">Copyright © 优仕教育  <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">津ICP备2023004451号</a></div>
      <div class="foot-bottom d-flex align-center justify-center">
        <div class="add">地址：天津市河西区围堤道146号华盛广场B座27层e单元</div>
        <div class="phone"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foots",
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
//底部
.foot {
  min-width: 1200px;
  height: 210px;
  background: #ffffff;
  padding-top: 70px;
  box-sizing: border-box;
  .foot-list {
    li {
      font-size: 14px;
      color: #666666;
      padding: 0 40px;
      box-sizing: border-box;
      position: relative;
      &:first-child {
        padding-left: 0;
      }
      &::after {
        content: "";
        height: 11px;
        width: 1px;
        background: rgba(102, 102, 102, 0.3);
        position: absolute;
        top: 5px;
        right: 0;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
  .icp {
    margin-top: 20px;
    font-size: 12px;
    color: #999999;
    text-align: center;
  }
  .foot-bottom {
    margin-top: 21px;
    .add {
      font-size: 12px;
      color: #666666;
    }
    .phone {
      font-size: 12px;
      color: #666666;
      margin-left: 40px;
      span {
        font-size: 12px;
        color: #5f2eff;
      }
    }
  }
}
</style>
