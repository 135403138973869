import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(window.localStorage.getItem("user"))
  },
  mutations: {
    userInfo(state, data) {
      state.user = data;
      // 为了防止页面刷新数据丢失，我们还需要把数据放到本地存储中，这里仅仅是为了持久化数据
      // setItem(USER_KEY, state.user)
      window.localStorage.setItem("user", JSON.stringify(state.user));
    }
  },
  actions: {},
  modules: {}
});
