<template>
  <div class="body">
    <heads></heads>
    <!-- 轮播 -->
    <div class="swiper-container swiper-banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <router-link to="/"
            ><img src="../../assets/img/index_banner.png" alt
          /></router-link>
        </div>
        <!-- <div class="swiper-slide">
            <router-link to="/"
              ><img src="../../assets/img/daily_bg.png" alt
            /></router-link>
          </div> -->
      </div>
    </div>
    <div class="certificate-bg d-flex align-center">
      <div class="certificate w">
        <div class="certificate-po">
          <img src="../../assets/img/certificate_p.png" alt="" />
        </div>
        <div class="certificate-title">有证 <span>你的人生</span>更美好</div>
        <p>升值加薪：学历是衡量员工的重要方面，直接与考核、工资、职位挂钩；</p>
        <p>考研究生：学历越高，好的就业机会越广，就业“钱”景越大；</p>
        <p>考取资格证：入职的必备条件，如教师证、健康管理师；</p>
      </div>
    </div>
    <div class="sort-bg">
      <div class="sort w">
        <img class="sort-title" src="../../assets/img/sort_title.png" alt="" />
        <ul class="list d-flex flex-wrap">
          <li
            class="item"
            v-for="(iten, index) in list.hotPackages"
            :key="index"
            @click="goDetail(iten)"
          >
            <img class="item-img" :src="iten.icon" alt="" />
            <div class="item-b">
              <p class="item-title">{{ iten.name }}</p>
              <!-- <p class="item-teacher">{{ iten.price }}</p> -->
              <div class="pic"><span>￥</span>{{ iten.price }}</div>
              <!-- <div
                class="item-btn d-flex align-center justify-center cursor"
                @click="goDetail(iten)"
              >
                查看详情
              </div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="team-bg">
      <div class="team w">
        <img class="team-title" src="../../assets/img/team_1.png" alt="" />
        <div class="d-flex">
          <div class="team-l">
            <img class="left-img" src="../../assets/img/sort_5.png" alt="" />
            <img class="left-bg" src="../../assets/img/team_2.png" alt="" />
          </div>
          <div class="team-r">
            <div class="present">
              <p class="team-name">杨凡</p>
              <p class="team-years">教师资格证课程讲师 15年教龄</p>
              <p class="team-txt">
                教育背景:
                本科、硕士均毕业于北京大学；从2000年就开始教授雅思课程，被誉为“雅思教学第一人”；从2001年至今一直在环球教育教学，是服务环球教育时间最长的教师；已出版的雅思学习经...
              </p>
              <img
                class="team-btn cursor"
                src="../../assets/img/team_7.png"
                alt=""
              />
            </div>
            <div class="team-swiper d-flex align-center">
              <img
                class="swiper-line"
                src="../../assets/img/team_4.png"
                alt=""
              />
              <div class="swiper-container team-list">
                <div class="swiper-wrapper">
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                  <div class="item swiper-slide">
                    <img class="swiper-img" src="../../assets/img/sort_5.png" />
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev team_prev"></div>
              <div class="swiper-button-next team_next"></div>
              <img
                class="swiper-line"
                src="../../assets/img/team_3.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="serve-bg">
      <div class="serve w">
        <img class="serve-title" src="../../assets/img/serve_1.png" alt="" />
        <ul class="serve-list d-flex">
          <li class="serve-item d-flex flex-column align-center">
            <img class="serve-img" src="../../assets/img/serve_2.png" alt="" />
            <p class="serve-name">实景精讲</p>
            <p class="serve-txt">实景授课 高效入门 眼见为实 过目难忘</p>
          </li>
          <li class="serve-item d-flex flex-column align-center">
            <img class="serve-img" src="../../assets/img/serve_3.png" alt="" />
            <p class="serve-name">互动直播</p>
            <p class="serve-txt">业内名师 方向把握 举一反三 考试预测</p>
          </li>
          <li class="serve-item d-flex flex-column align-center">
            <img class="serve-img" src="../../assets/img/serve_4.png" alt="" />
            <p class="serve-name">智能题库</p>
            <p class="serve-txt">海量习题 知识串联 精华压缩 重点解析</p>
          </li>
          <li class="serve-item d-flex flex-column align-center">
            <img class="serve-img" src="../../assets/img/serve_5.png" alt="" />
            <p class="serve-name">专辅答疑</p>
            <p class="serve-txt">学习指导 进度追踪 贴心服务 一路随行</p>
          </li>
          <li class="serve-item d-flex flex-column align-center">
            <img class="serve-img" src="../../assets/img/serve_6.png" alt="" />
            <p class="serve-name">AI定制</p>
            <p class="serve-txt">个性推荐 因材施教 量身定制 智能服务</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="grab">
      <div class="grab-top d-flex align-center">
        <img class="grab-title" src="../../assets/img/grab_2.png" alt="" />
        <ul class="grab-list d-flex align-center">
          <li class="grab-item d-flex align-center justify-center">
            {{ hour }}
          </li>
          <!-- <li class="grab-item d-flex align-center justify-center">2</li> -->
          <li class="item-dian d-flex align-center justify-center">:</li>
          <li class="grab-item d-flex align-center justify-center">
            {{ min }}
          </li>
          <!-- <li class="grab-item d-flex align-center justify-center">2</li> -->
          <li class="item-dian d-flex align-center justify-center">:</li>
          <!-- <li class="grab-item d-flex align-center justify-center">2</li> -->
          <li class="grab-item d-flex align-center justify-center">
            {{ second }}
          </li>
        </ul>
      </div>
      <div class="grab-bottom d-flex align-center">
        <div class="swiper-container swiper-grab">
          <div class="swiper-wrapper">
            <div
              class="item swiper-slide"
              v-for="(items, indexs) in list.timePackages"
              :key="indexs"
            >
              <img class="item-img" :src="items.icon" />
              <div class="item-conter d-flex justify-between flex-column">
                <p class="item-title">{{ items.name }}</p>
                <div class="item-bottom d-flex justify-between align-center">
                  <div class="item-pic">
                    <span>￥</span>
                    {{ items.price }}
                  </div>
                  <div
                    class="item-btn d-flex align-center justify-center cursor"
                    @click="goDetails(items)"
                  >
                    立即购买
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev grab_prev cursor"></div>
        <div class="swiper-button-next grab_next cursor"></div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
export default {
  data() {
    return {
      list: "",
      curStartTime: "",
      hour: "",
      min: "",
      second: ""
    };
  },
  mounted() {
    let mySwiper = new Swiper(".team-list", {
      loop: false, // 循环模式选项
      slidesPerView: 9,
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".team_next ",
        prevEl: ".team_prev"
      }
    });
    let mySwiper1 = new Swiper(".swiper-grab", {
      loop: false, // 循环模式选项
      slidesPerView: 3,
      spaceBetween: 22,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".grab_next ",
        prevEl: ".grab_prev"
      }
    });
    this.getInit();
    this.countTime();
  },
  methods: {
    async getInit(params) {
      const res = await this.$ajaxRequest('get', 'initData')
      console.log(res.data);
      this.list = res.data;
    },
    // 热门跳转
    goDetail(item) {
      // console.log(item)
      const user = JSON.parse(window.localStorage.getItem("user"));
      if (!user) {
        this.$router.push("login");
      } else {
        console.log(item.ctype)
        if (item.ctype == 0) {
          this.$router.push({
            name: `courseDetail`,
            query: {
              id: item.id,
              type: '0'
            }
          });
        } else if (item.ctype == 2) {
          this.$router.push({
            name: `liveDetail`,
            query: {
              id: item.id,
              type: '0'
            }
          });
        }
      }
    },
    // 限时跳转
    goDetails(item) {
      const user = JSON.parse(window.localStorage.getItem("user"));
      if (!user) {
        this.$router.push("login");
      } else {
        if (item.ctype == 0) {
          this.$router.push({
            name: `courseDetail`,
            query: {
              id: item.id,
              type: '1'
            }
          });
        } else if (item.ctype == 2) {
          this.$router.push({
            name: `liveDetail`,
            query: {
              id: item.id,
              type: '1'
            }
          });
        }
      }
    },
    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      let da = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate() + 1
      };
      let systemTime = da.year + "-" + da.month + "-" + da.date;
      // 设置截止时间
      let endDate = new Date(systemTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.body {
  background: #ffffff !important;
}
// 轮播
.swiper-banner {
  max-width: 1920px;
  min-width: 1200px;
  height: 630px;
}

.swiper-slide {
  height: 100%;
  width: 100%;
}
.certificate-bg {
  height: 398px;
  min-width: 1200px;
  box-sizing: border-box;
  // background: url(../../assets/img/certificate_bg.png) center no-repeat;
  .certificate {
    height: 225px;
    width: 1100px;
    background: url(../../assets/img/certificate_sbg.png) center no-repeat;
    position: relative;
    padding-left: 52px;
    padding-top: 50px;
    box-sizing: border-box;
    .certificate-po {
      height: 277px;
      height: 96px;
      position: absolute;
      left: -14px;
      top: -48px;
    }
    .certificate-title {
      font-size: 30px;
      font-weight: bold;
      color: #4f3acf;
      margin-bottom: 22px;
      span {
        font-size: 30px;
        font-weight: bold;
        color: #444444;
      }
    }
    p {
      font-size: 18px;
      color: #333333;
      margin-bottom: 10px;
    }
  }
}
.sort-bg {
  height: 750px;
  min-width: 1200px;
  box-sizing: border-box;
  // background: url(../../assets/img/sort_bg.png) center no-repeat;
}
.sort {
  .sort-title {
    width: 593px;
    height: 131px;
    margin: 0 auto;
  }
  .item {
    width: 278px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(144, 144, 144, 0.5);
    margin-right: 29px;
    margin-top: 50px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .item-img {
      width: 278px;
      height: 160px;
      object-fit: contain;
    }
    .item-b {
      padding: 12px 14px 17px 12px;
      box-sizing: border-box;
      .item-title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      .pic {
        font-size: 29px;
        color: #f53444;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 10px;
        span {
          font-size: 19px;
          color: #f53444;
        }
      }
      .item-btn {
        width: 108px;
        height: 34px;
        border: 1px solid #5f2eff;
        border-radius: 17px;
        font-size: 18px;
        color: #5f2eff;
        margin-left: auto;
      }
    }
  }
}
.team-bg {
  height: 807px;
  min-width: 1200px;
  box-sizing: border-box;
  // background: url(../../assets/img/team_bg.png) center no-repeat;

  .team {
    // margin-top: 100px;
    padding-top: 80px;
    box-sizing: border-box;
    .team-title {
      height: 133px;
      width: 515px;
      margin: 0 auto;
    }
    .team-l {
      position: relative;
      .left-img {
        width: 260px;
        height: 360px;
        margin-top: 117px;
        margin-left: 64px;
        position: relative;
        z-index: 10;
      }
      .left-bg {
        width: 236px;
        height: 107px;
        position: absolute;
        top: 90px;
        left: 115px;
      }
    }
    .team-r {
      margin-left: 39px;
      margin-top: 136px;
      .present {
        width: 613px;
        margin-left: 65px;
        .team-name {
          font-size: 38px;
          font-weight: bold;
          color: #ffffff;
        }
        .team-years {
          font-size: 20px;
          color: #ffffff;
          margin-top: 10px;
        }
        .team-txt {
          width: 613px;
          height: 77px;
          font-size: 16px;
          color: #ffffff;
          line-height: 27px;
          opacity: 0.7;
          margin-top: 10px;
        }
        .team-btn {
          width: 128px;
          height: 38px;
          margin-left: auto;
          margin-top: 7px;
        }
      }
      .team-swiper {
        margin-top: 24px;
        position: relative;
        .swiper-line {
          height: 98px;
          width: 15px;
        }
        .team-list {
          width: 710px;
          margin: 0 48px;
          .item {
            width: 72px;
            height: 86px;
            &:hover {
              background: #1e1e1e;
              opacity: 0.5;
            }
          }
        }
        .team_prev {
          width: 24px;
          height: 86px;
          margin-top: -43px;
          background: url(../../assets/img/team_6.png) center no-repeat;
          left: 33px;
          cursor: pointer;
          z-index: 100;
          outline: none;
        }

        .team_next {
          width: 24px;
          height: 86px;
          margin-top: -43px;
          background: url(../../assets/img/team_5.png) center no-repeat;
          right: 33px;
          cursor: pointer;
          z-index: 100;
          outline: none;
        }
      }
    }
  }
}
.serve-bg {
  height: 598px;
  min-width: 1200px;
  box-sizing: border-box;
  // background: url(../../assets/img/serve_bg.png) center no-repeat;
  margin-top: 80px;
}
.serve {
  .serve-title {
    width: 437px;
    height: 131px;
    margin: 0 auto;
  }
  .serve-list {
    .serve-item {
      padding: 42px 21px;
      box-sizing: border-box;
      margin-top: 50px;

      .serve-img {
        width: 205px;
        height: 131px;
      }
      .serve-name {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 32px;
        margin-bottom: 17px;
      }
      .serve-txt {
        width: 120px;
        height: 34px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
      &:hover {
        background: #ffffff;
        box-shadow: 0px 2px 18px 0px rgba(144, 144, 144, 0.3);
      }
    }
  }
}
.grab {
  width: 1552px;
  height: 485px;
  margin: 0 auto;
  background: url(../../assets/img/grab_1.png) center no-repeat;
  padding: 89px 101px 59px 519px;
  box-sizing: border-box;
  .grab-title {
    width: 185px;
    height: 48px;
  }
  .grab-list {
    margin-left: 15px;
    .grab-item {
      width: 24px;
      height: 28px;
      border: 1px solid #ffffff;
      opacity: 0.8;
      border-radius: 4px;
      font-size: 22px;
      color: #ffd248;
      margin-right: 3px;
    }
    .item-dian {
      font-size: 26px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
      height: 28px;
      margin-right: 3px;
    }
  }
  .grab-bottom {
    width: 932px;
    height: 268px;
    background: url(../../assets/img/grab_3.png) center no-repeat;
    margin-top: 21px;
    padding: 0 54px;
    position: relative;
    box-sizing: border-box;
    .swiper-grab {
      height: 236px;
      width: 824px;
      .item {
        width: 260px;
        // height: 250px;
        background: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        .item-img {
          width: 260px !important;
          height: 150px;
          object-fit: fill;
        }
        .item-conter {
          padding: 10px 12px 12px 12px;
          box-sizing: border-box;
        }
        .item-title {
          font-size: 16px;
          color: #444444;
        }
        .item-bottom {
          margin-top: 9px;
        }
        .item-pic {
          font-size: 20px;
          font-weight: bold;
          color: #ff505f;
          span {
            font-size: 14px;
            color: #ff505f;
          }
        }
        .item-btn {
          width: 78px;
          height: 24px;
          border: 1px solid #5f2eff;
          border-radius: 12px;
          font-size: 14px;
          color: #5f2eff;
        }
      }
    }
    .grab_prev {
      width: 30px;
      height: 30px;
      background: url(../../assets/img/grab_4.png) center no-repeat;
      left: 14px;
      cursor: pointer;
      z-index: 100;
      outline: none;
    }

    .grab_next {
      width: 30px;
      height: 30px;
      background: url(../../assets/img/grab_5.png) center no-repeat;
      right: 14px;
      cursor: pointer;
      z-index: 100;
      outline: none;
    }
  }
}
</style>
