const urlConst = {
  /**
   * 公共模块API
   **/
  packagesSort: "/Service-Course/api/major/initData", //课程中心科目
  queryList: "/Service-Order/api/student/sourceCenter", //课程中心列表
  initData: "/Service-Order/api/index/packages", //首页
  login: "/Service-Student/api/student/login", //登录
  packageDetail: "/Service-Order/api/student/package/detail", //销售包详情
  sourcePackagesLabels: "/Service-Order/api/student/package/other", //获取课程详情右侧销售包
  catalogueLive: "/Service-Order/api/student/package/liveList", //查询直播目录列表
  sourceCatalogue: "/Service-Order/api/student/package/courseList", //查询录播目录列表
  liveList: "/Service-Order/api/student/liveCenter", //直播中心
  queryPackageDetail: "/Service-Order/api/student/order/detail", //订单结算页详情信息
  queryOrderStatus: "/Service-Order/api/student/order/queryOrderStatus", //定时查单接口
  create: "/Service-Order/api/student/order/create", //开始购买
  toPay: "/Service-Order/api/student/order/toPay", //开始购买
  myOrders: "/Service-Order/api/student/userOrders", //订单
  saveMsg: "/Service-Order/api/student/signature", //查看合同
  queryByOrderId: "/Service-Order/api/userOrder", //查询订单
  myLives: "/Service-Order/api/student/myLives", //我的直播课程
  myLiveLessons: "/Service-Live/api/live/liveLesson", //我的直播课件
  getAllMajor: "/Service-Course/api/majorids", //查询所有科目
  myCourses: "/Service-Order/api/student/myCourses", //我的录播课包
  myCourseLessons: "Service-Online/api/lessons/import", //录播课件
  initDatatiku: "Service-Exam/student/error/initData", //题库科目
  accuracy: "Service-Exam/student/error/accuracy", //正确率
  dailyList: "Service-Exam/student/exam/daily/queryList", //每日一练列表
  stageList: "Service-Exam/student/exam/stage/queryList", //阶段测试列表
  paperList: "Service-Exam/student/exam/paper/queryList", //历年真题列表
  continue: "Service-Exam/student/exam/continue", //历年真题列表
  showSection: "Service-Exam/student/exam/showSections", //收藏列表章节
  showQuestion: "Service-Exam/student/exam/showQuestions", //收藏列表题型
  errorShow: "Service-Exam/student/error/show", //错题集列表
  errorList: "Service-Exam/student/error/list", //记录列表
  collect: "Service-Exam/student/exam/collect", //收藏题目
  begin: "Service-Exam/student/exam/begin", //进入测试页面
  exercise: "Service-Exam/student/exam/exercise", //收藏做题
  finishPc: "Service-Exam/student/exam/finishPc", //提交
  showError: "Service-Exam/student/error/showError", //错题集点击查看
  update: "Service-Student/api/student/selfData", //修改个人资料
  uploadImage: "Service-Online/api/online/file", //图片上传
  updateUpass: "Service-Student/api/student/pass", //修改密码
  nextRandCode: "/Service-Student/api/nextRandCode", //验证码
  loginByPhone: "/Service-Student/api/student/loginByPhone", //手机验证码登录
  savePicture: "/Service-Student/api/student/icon", //修改头像
  sendCode: "/Service-Student/api/sendSms", //登录短信验证码
  register: "/Service-Student/api/student/register", //注册
  sendCodeNotImgCode: "/Service-Student/api/sendSmsRegister", //注册短信验证码
  queryByPhone: "/Service-Student/api/student/phoneExist", //手机号是否注册
  payDoc: "Service-Order/api/student/package/document/nofree", //付费资料包
  freeDoc: "Service-Order/api/student/package/document/free", //学员免费课程资料
  queryStudyHistory: "Service-Student/api/student/history/list", //听课记录
  uploadSig: "Service-Order/api/student/uploadSig", //听课记录
}
export default urlConst
