import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    component: Index
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/user"),
    children: [
      {
        path: "/question", //题库
        name: "question",
        component: () => import("../views/user/question")
      },
      {
        path: "/myOrder", //订单
        name: "myOrder",
        component: () => import("../views/user/myOrder")
      },
      {
        path: "/collect", //试题收藏
        name: "collect",
        component: () => import("../views/user/collect")
      },
      {
        path: "/myLive", //我的直播
        name: "myLive",
        component: () => import("../views/user/myLive")
      },
      {
        path: "/start", //开始听课
        name: "start",
        component: () => import("../views/user/start")
      },
      {
        path: "/recording", //录播课程
        name: "recording",
        component: () => import("../views/user/recording")
      },
      {
        path: "/livePlay", //直播课程
        name: "livePlay",
        component: () => import("../views/user/livePlay")
      },
      {
        path: "/dataList", //资料列表
        name: "dataList",
        component: () => import("../views/user/dataList")
      },
      {
        path: "/classCollect", //课程收藏
        name: "classCollect",
        component: () => import("../views/user/classCollect")
      },
      {
        path: "/record", //听课记录
        name: "record",
        component: () => import("../views/user/record")
      },
      {
        path: "/wrong", //cuoti
        name: "wrong",
        component: () => import("../views/user/wrong")
      },
      {
        path: "/annal", //记录
        name: "annal",
        component: () => import("../views/user/annal")
      },
      {
        path: "/information", //资料
        name: "information",
        component: () => import("../views/user/information")
      },
      {
        path: "/account", //资料
        name: "account",
        component: () => import("../views/user/account")
      }
    ]
  },
  {
    path: "/exam",
    name: "exam",
    component: () => import("../views/exam/exam")
  },
  {
    path: "/wrongExam",
    name: "wrongExam",
    component: () => import("../views/exam/wrongExam")
  },
  {
    path: "/login", //登录
    name: "login",
    component: () => import("../views/login/login")
  },
  {
    path: "/course", //课程中心
    name: "course",
    component: () => import("../views/course/course")
  },
  {
    path: "/live", //课程中心
    name: "live",
    component: () => import("../views/live/live")
    // children: [
    //   {
    //     path: "/liveDetail", //题库
    //     name: "liveDetail",
    //     component: () => import("../views/live/liveDetail")
    //   }
    // ]
  },
  {
    path: "/liveDetail", //题库
    name: "liveDetail",
    component: () => import("../views/live/liveDetail")
  },
  {
    path: "/courseDetail", //题库
    name: "courseDetail",
    component: () => import("../views/course/courseDetail")
  },
  {
    path: "/teachers", //题库
    name: "teachers",
    component: () => import("../views/teachers/teachers")
  },
  {
    path: "/teachersDetail", //题库
    name: "teachersDetail",
    component: () => import("../views/teachers/teachersDetail")
  },
  {
    path: "/order", //订单结算
    name: "order",
    component: () => import("../views/order/order")
  },
  {
    path: "/sketchpad", //订单结算
    name: "sketchpad",
    component: () => import("../views/user/sketchpad")
  },
  {
    path: "/orderPay", //订单结算
    name: "orderPay",
    component: () => import("../views/order/orderPay")
  }
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  mode:'history',
  routes
});

export default router;
