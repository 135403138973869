export default {
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'http://192.168.0.116:8080'
  // baseURL: "http://192.168.1.125:80"
  // baseURL: "http://192.168.1.12:8082"
  // baseURL: "http://47.92.133.173:8082"
  // baseURL: "https://mvpaaa.com:443"
  // baseURL: "http://ls.mvpaaa.com:8082"
  // baseURL: "http://lxzx.mvpaaa.com:8082"
  // baseURL: "http://ls.mvpaaa.com:8082"
  // baseURL: "/"
  baseURL: "https://mvpaaa.com:9000"
};
